.main{
display: flex;
flex-direction: column;
background-color: #17181A;

}
.sec1{
display: flex;
flex: row;
padding-top: 100px;
padding-left: 10%;
padding-right: 10%;
padding-bottom: 50px;
}
.sec1-1{
p{
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 1rem;
    line-height: 40px;
    color: whitesmoke;

}

}
.sec1-2{
background-image: url(../../assets/res1.png);
background-size: cover;
background-repeat: no-repeat;
width: 100vw;
padding-right: 20%;
padding-left: 10px;




}
.sec2{
    background-image: url(../../assets/res2.png);
    background-size:cover;
    background-repeat: no-repeat;
    background-position: left;
    width: 100vw;
    height: 320px;

}
.sec3{
    background-color: whitesmoke;
}
.sec4{
    background-image: url(../../assets/res4.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 655px;
    background-position: center;

}
.sec5{
    background-image: url(../../assets/res3.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 80vw;
    height: 400px;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 50px;
    
 
    
}
.slogan1{
    display: flex;
    align-items: center;
    text-align: center;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 2.5rem;
    color:#EF7D00;
    margin-top: 2rem;
    margin-left: 10%;
}
.text1{
    font-family: 'Open Sans';
    font-size: 1rem;
    font-weight: 400;
    line-height: 40px;
    text-align: justify;
    color: #17181A;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 1rem;
    margin-bottom: 30px;
    

}

@media  screen and (max-width: 1050px) {
    
}
@media  screen and (max-width: 700px) {
.sec1{
    flex-direction: column-reverse;
}
.sec1-2{
    
    background-size: contain;
    width: 80vw;
    height: 125px;
    padding-left: auto;
    margin-bottom: 10px;
    
    }
    .sec2{
        
        background-size:cover;
        background-position: center;
        width: 100%;
        min-height: 150px;
        
    
    }
    .sec5{
        
        background-size: cover;
        width: 80%;
        height: 150px;
        margin-left: 10%;
        margin-right: 10%;
        margin-bottom: 10px;
        
    }
    .sec4{
        
        background-size: cover;
        background-repeat: no-repeat;
        width: 100vw;
        height: 150px;
        background-position: center;
    
    }
}
@media  screen and (max-width: 550px) {
    .sec1-2{
        background-position: center;
        background-size: cover;
        width: auto;
        height: 500px;
        padding-left: auto;
        margin-bottom: 0px;
        
        }
}