
.back{

    position: relative;
    object-fit: cover;
    width: 100%;
    height: 85vh;
    // background-image: url(../../assets/back2.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -100;
    overflow: hidden;
    background-color: black;
}
.elek{
    background-image: url(../../assets/component.png);
    width: 75vw;
    height: 75vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;

}
// .container {
//     position: relative;
//     width: 100%;
//     height: 100vh;
//     overflow: hidden;
// }
.layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}
.background {
    background-image: url('../../assets/back/background.png');
    z-index: 1;
}
.time-machine {
    background-image: url('../../assets/back/timemachine.png');
    z-index: 2;
}

.foreground {
    background-image: url('../../assets/back/foreground.png');
    z-index: 4;
}
#background-video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 10;
  }

.video{
    position: absolute;
    z-index: 0;
    top: 25%;
    right: 10%;
    width: 35%;
    height: 35%;
    object-fit: cover;
    // background-color: black;
   
}
.thnks{
    position: absolute;
    color: #fff;
    text-align: justify;
    width: 35%;
   background-color: black;
   opacity:100%;
   padding: 25px;
   margin-top:12%;
   right: 10%;
   scroll-behavior: auto;
    
}

.icon{
    width: 100px;
    display: flex;
    color: #fff;
    bottom: -80px;
    padding-left: 50px;
    justify-content: space-between;
    position: absolute;
    z-index: 200;
    margin-bottom: 0;

    
}
.icon a{
    &:hover{
       
        transform: scale(1.5);    
    }
}
@media screen and (max-width:900px){
    .video{
        display: none;
        // position: absolute;
        // z-index: 0;
        // top: 65%;
        // left: 10%;
        // width: 70%;
        // height: 30%;
        // object-fit: cover;
        // margin: auto;
       
    }
    .thnks{
        position: absolute;
        color: #fff;
        // margin: 0 auto;
        width: 75%;
       background-color: black;
       opacity: 80%;
       padding: 25px;
       margin-top: 150px;
       right: 10%;
       scroll-behavior: auto;
        
    }

}
@media screen and (max-width:550px){
    .video{
        display: none;
        // position: absolute;
        // z-index: 0;
        // top: 55%;
        // left: 10%;
        // width: 70%;
        // height: 30%;
        // object-fit: cover;
        // margin: auto;
       
    }
}