.kasaalt{
    background-image: url(../../assets/kasalat.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 450px;
    padding-bottom: 0;
    padding-top: 20px;
    background-position:bottom;
}
.seruven{
    
    background-image: url(../../assets/seruven.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 450px;
    padding-bottom: 0px;
    background-position:top;
    
}
.elekkasa{
    background-color: #17181A;
    justify-content: stretch;
    margin: auto;
}
.grid{
    display: flex;
    // display: grid;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // grid-template-columns: repeat(3, 1fr);
     margin:auto;
     padding-top: 50px;
    //  justify-content: space-around;
    
}
.kasakasa{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}
.sag1{
   
    background-position: bottom;
    background-blend-mode: lighten;
    // width: 80vw;
    // height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    
   
   
}
.kasa{
    
    text-align: center;
    padding-inline-start: 20px;
    padding-inline-end: 20px;
    margin-bottom: 50px;
}
.kasatext{
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    text-align: justify;
    // color:#17181A;
    justify-content: center;
    align-items: center;
    // z-index:100;
    font-size: 16px;
    font-weight: 300;
    color: #c59f3e;
    transition: transform .5s;
    line-height: 0.5;
    &:hover{
        transform: scale(1.1);
    }
}
.kasa button{
    padding: 4px 40px;
    font-size: 13px;
    color: #c59f3e;
    border: 1px solid #b48a20;
    background: transparent;
    transition: color .5s, background-color .5s, transform .5s;
    &:hover{
        
        background:#b48a20;
        color: #fff;
    }
}
.sectiona{
    margin-top: 50px;
    margin-bottom: 0px;
    background-color: #17181A;

}

.kasaresim img{
    height: 100px;
    margin: 50px 0;
   
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
    &:hover{
        -webkit-filter: grayscale(100%) blur(1px);
        transform: scale(1.2);    
    }
}

@media  screen and (max-width: 1050px) {
    .sag1{
        flex-direction: column;
    }
    .kasakasa{
        display: flex;
        flex-direction: column;
    }
        
}
@media  screen and (max-width: 700px) {
    .sag1{
        flex-direction: column;
    }
    .kasaalt{
        
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 150px;
        scroll-padding-bottom: 50px;
        background-position:top;
        
    }
    }
@media  screen and (max-width: 550px) {
    .kasa{
    
        text-align: center;
        padding-inline-start: 0px;
        padding-inline-end: 0px;
        margin-bottom: 20px;
    }
}

