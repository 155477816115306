.card{
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    padding-left: 25px;
    padding-right: 35px;
    padding-bottom: 30px;
    background-color:#17181A;
    
}
.hr{
    color: #c59f3e;
}
.urun-altdetay2{
    display: flex;
    flex-direction: row;
    // width: 100vw;
    justify-content: center;
    margin: auto;
    padding: 40px;
}
.alt-baslik{
    color:#c59f3e;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 58px;
    text-transform: uppercase;
    line-height: 1;
    padding-left: 40px;
}
// .alt-resim img{
//     width: 600px;
//     padding-left: 20px;
//     margin: auto;
   
// }
.alt-metin{
    color:#fff;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 200;
    font-size: 1rem;
    line-height: 58px;
    line-height: 1.3rem;
    text-align: justify;
   padding-top: 20px;

}
.alt-resimler{
    display: flex; 
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.yatay{
    flex-direction: column;

}
#alt-resim img{
   width: 40vw;
    border-radius: 40px;
    stroke-linecap: round;
    stroke: #fff;
    padding: 10px;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
    &:hover{
        
        transform: scale(1.2); 
        }
}
@media  screen and (max-width: 1300px) {
    .alt-resimler{
        display: flex; 
        flex-direction: column;
       
    }
    .yatay{
        flex-direction: column;
        column-gap: 10px;
        row-gap: 10px;
    }
 
        
}
@media  screen and (max-width: 900px) {
    .urun-altdetay{
        flex-direction: column;
    }


   

    }
@media  screen and (max-width: 6600px) {

   
    }