.kvkk{
    padding: 2rem;
    background-color: #17181A;
}
.baslik{
    text-align: center;
    padding-top: 20px;
    color: #fff;
    font-weight: 300;
}
.metin{
    font-size: 1rem;
    color: #fff;
    font-weight: 200;
    margin:50px 80px;
    justify-content: baseline;
    text-align: justify;

}