@import "./index.scss";

$color-main:#17181A;
$color-orn:#EF7D00;
$color-ant:#323033;
$color-sc:#565656;

a{
  color:unset;
  
}

.lang{
  list-style-type: none;
  flex-direction:row;
  border-style: none;
  background: none;
  display:grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  position: relative;
  color: #fff;
  
}
.lang
:hover{
   color:whitesmoke;
}

.language-select{
  
  text-decoration: none;
  position:absolute;
  z-index: 1;
	display: block;
	top:1rem;
	right: 2rem;
  font-size: 1rem;
  cursor: pointer;
  text-transform: uppercase;
  
    &:hover{
        color:#fff;
    }
}
@media  screen and (max-width: 1280px) 
      {
        .lang{
        margin: 0.3rem;
        }
        .language-select{
          top:1rem;
          right: 2rem;
        }
          
      }
@media screen and (max-width: 900px) {
  .lang{
    margin: 0.3rem;
  }
  .language-select{
    top:0.5rem;
	right: 2rem;
  }
}

@media screen and (max-width:550px){
  .lang{
    margin: 0.3rem;
    
  }
  .language-select{
    top:0.5rem;
	  right: 2rem;
  }
    
}