.anatemiz{
    background-color: #17181A;
   
    // padding: 0;
}
.urun-grup{
    background-color: #fff;
}
.temizleme{
    margin: 0 auto;
    padding: 10% 10% 10% 10%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    // margin-left: 10%;
    // margin-right: 10%;
    // padding: auto;
    row-gap: 30px;
    column-gap: 20px;
    align-items: left;
    // width: 85vw;
}
#tem-baslik{
    color:#c59f3e;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 58px;
    text-transform: uppercase;
    line-height: 1;
    
  
    
}
#tem-yatay{
    //display: flex;
    //flex-direction: row;
    // justify-content: left;

    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Her satırda 3 sütun */
    gap: 20px;


}
.button-hr{
    margin:0.4rem 0;
}
.tem-urun{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:flex-start;
    img{
        width: 300px;
        height: 200px;
        &:hover{
            -webkit-filter: grayscale(100%);
            transition: transform 0.5s;
            transform: scale(1.2); 
            }
        

    }
}
.buton{
    color: #c59f3e;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 1.5;
    width: 150px;
    &:hover{
        transform: scale(1.1);
        color: #fff !important;
    }
}


@media  screen and (max-width: 1150px) {
    .temizleme{
        padding: 7%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: center;
        // margin-left: 10%;
        // margin-right: 10%;
        // padding: auto;
        row-gap: 5px;
        column-gap: 5px;
    }
    #tem-yatay{
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* Her satırda 3 sütun */
        gap: 20px;
    }
 
        
}
@media  screen and (max-width: 950px) {
    #tem-yatay{
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Her satırda 3 sütun */
        gap: 20px;
    }
    }
@media  screen and (max-width: 590px) {
    .tem-urun{
        flex-direction: column;
        left: 20px;
    }
    #tem-yatay{
        display: grid;
        grid-template-columns: repeat(1, 1fr); /* Her satırda 3 sütun */
        gap: 20px;
    }
    .temizleme{
        
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: center;
        row-gap: 40px;
        column-gap: 5px;
        left: 30px;
    }
}