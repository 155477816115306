@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap');

*{
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  scroll-behavior: smooth;
  
}

$color-main:#17181A;
$color-orn:#EF7D00;
$color-ant:#323033;
$color-sc:#565656;
$font-family: 'Open Sans', sans-serif;
$mainTransition:all 0,3s linear;

body {
  margin: 0;
  $font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Open Sans', sans-serif;
}
a{
  color:unset;
  text-decoration: none;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }
  
  @-webkit-keyframes scale-up-center {
  0% {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  }
  100% {
  -webkit-transform: scale(1);
          transform: scale(1);
  }
  }
  
  @keyframes scale-up-center {
  0% {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  }
  100% {
  -webkit-transform: scale(1);
          transform: scale(1);
  }
  }