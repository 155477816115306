.card2{
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    padding-left: 25px;
    padding-right: 35px;
    padding-bottom: 30px;
    background-color:#17181A ;
    
}
.hr{
    color: #c59f3e;
}
.urun__altdetay{
    display: flex;
    flex-direction: row;
    width: 85vw;
}
.alt-baslik{
    color:#c59f3e;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 58px;
    text-transform: uppercase;
    line-height: 1;
    padding-left: 40px;
}
.alt-resim img{
    width: 500px;
   
}
.alt-metin{
    color:#fff;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 200;
    font-size: 1rem;
    line-height: 58px;
    line-height: 1.3rem;
    text-align: justify;
   padding-top: 20px;

}
@media  screen and (max-width: 1050px) {
    .urun-altdetay{
        flex-direction: row;
    }
 
        
}
@media  screen and (max-width: 900px) {
    .urun__altdetay{
        flex-direction: column;
    }
    .alt-metin{
        padding-left: 40px;
    }
 
    }
@media  screen and (max-width: 550px) {
    .alt-resim img{
        width: 300px;
       
    }
   
}