// .urun-slo{
//     display: flex;
//     flex: 2;
//     flex-direction: column;
//     position: absolute;
//     align-items: right;
//     text-align:right;
//     color: whitesmoke;
//     justify-content: flex-end;
//     align-items: right;
//     z-index:1;
//     right: 40px;
//     top: 240px;
// }
.urun-grup{
    
    background-position: bottom;
    background-blend-mode: lighten;
    // width: 80vw;
    // height: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    // background-image: url(../../assets/basak2.png);
    background-repeat: no-repeat;
    background-size: fixed;
    background-position: right bottom;
   
}

.section{
    margin-top: 50px;
    margin-bottom: 0px;
}

.video1{
    position: relative;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 60%;
    object-fit: cover;
    
  }
.urunalt{
    
    background-image: url(../../assets/urunalt.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 300px;
    scroll-padding-bottom: 50px;
    background-position:top;
    
   
}
.prensip{
    width: 70%;
    display: flex;
    flex-direction: row;
    // display: grid ;
    // grid-template-columns: repeat(auto-fit, minmax(300px,3fr));
    margin:auto;
    column-gap: 20px;
      
    
}
.urun1{
    
    text-align: center;
    padding-inline-start: 30px;
    padding-inline-end: 30px;
}
.urun1 button{
    padding: 4px 40px;
    font-size: 13px;
    color: #c59f3e;
    border: 1px solid #b48a20;
    background: transparent;
    transition: color .5s, background-color .5s, transform .5s;
    &:hover{
        
        background:#b48a20;
        color: #fff;
    }
}
.section{
    // background-color: #fff;
    padding-bottom: 50px;
}


   .baslik p{
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    text-align: justify;
    // color:#17181A;
    justify-content: center;
    align-items: center;
    // z-index:100;
    font-size: 16px;
    font-weight: 300;
    color: #c59f3e;
    transition: transform .5s;
    line-height: 0.5;
    &:hover{
        transform: scale(1.1);
    }
}
    
.resim img{
    height: 150px;
    margin: 20px 0;
    -webkit-filter: grayscale(0) blur(0);
	
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
    &:hover{
        -webkit-filter: grayscale(100%) blur(1px);
        transform: scale(1.2); 
        }
   
    }


.swiper {
    width: 100%;
    height: 20%;
    
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items:flex-end;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
    @media  screen and (max-width: 1050px) {
        
        
    }
    @media  screen and (max-width: 700px) {
        .urun-grup{
            flex-direction: column;
        }
        .urunalt{
            
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            height: 150px;
            scroll-padding-bottom: 50px;
            background-position:top;
            
        }
        }
    @media  screen and (max-width: 550px) {
        .resim img{
            width: 250px;
        }
    
    }