.footer{
    background-color: #fff;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    // height: 550px;
    justify-content: space-between;
    width: 100%;
    background-image: url(../../assets/basak.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    overflow: hidden;

}
.sag{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 20px;
    text-align: right;
    align-items: flex-end;
    padding-right: 20px;
    padding-bottom: 10px;
    
}
.social{
    font-size: 20px;
    color: #17181A;
    display: flex;
    width: 100px;
    justify-content: space-around;
      
}
.social a{
    
    &:hover{
       
        transform: scale(1.5);    
    }
}
.desmud{
    right: 10px;
}
.bol1{
   
    height: 150px;
}
.logo{
    background-image: url(../../assets/logo2.png);
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
    width: 200px;
    height: 93px;
    margin-top: 50px;

}
.adres{
    display: flex;
    flex-direction: column;
    padding-left: 10%;
    padding-bottom: 10px;
}
.bol2{
    display: flex;
    flex-direction: row;
}
.adres2{
    display: flex;
    flex-direction: column;
    
}
.adres1{
    display: flex;
    flex-direction: row;
    padding-left: 50px;
    width: 250px;
    
}
.adres1 p, .mail{
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 0.8rem;
    text-align: left;
    padding-block-start: 4px;
   
}
.icons{
    color: #17181A;
    font-size: 1rem;
    padding: 5px;
    padding-block-start: 0;
}

   .sag a{
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 0.9rem;
    text-align: right;
    color: #17181A;
    

   }

   @media  screen and (max-width: 1050px) {
    
   }
   @media  screen and (max-width: 700px) {

   }
   @media  screen and (max-width: 550px) {
        .footer{
            flex-direction: column;
        }
        .sag{
            flex-wrap: wrap;
            text-align: left;
            align-items: flex-start;
            padding-left: 20%;
            justify-content: space-between;
        }
   }