.ekipman{
  padding: 30px;
    margin: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px,4fr));
  column-gap: 10px;
  align-content: center;
  justify-items: center;
  
  p{
    padding: 13px 40px;
    font-size: 13px;
    color: #c59f3e;
    border: 1px solid #b48a20;
    background: transparent;
    text-align: center;
    transition: color .5s, background-color .5s, transform .5s;
    &:hover{
        
        background:#b48a20;
        color: #fff;
    }
  }
  img{
    padding-bottom: 40px;
    transition: .3s ease-in-out;
   align-self: center;
    &:hover{
        
      transform: scale(1.2); 
      }
  }
}