@import "../../index.scss";
ul{
    margin:0;
    padding:0;
    display:flex;
  }
  
  ul li{
    list-style:none;
    margin:0 20px;
    transition:0.5s;
  }
  
  ul li a{
    display: block;
    position:relative;
    text-decoration:none;
    padding:5px;
    font-size:0.8rem;
    color:#fff;
    text-transform:uppercase;
    transition:0.5s;
  }
  
  ul:hover li a{
    transform:scale(1.5);
    opacity:0.2;
    filter:blur(5px);
  }
  
  ul li a:hover{
    transform:scale(2);
    opacity:1;
    filter:blur(0);
    text-decoration:none;
    color:#fff;
  }
  
  ul li a:before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:#17181A;
    transition:0.5s;
    transform-origin:right;
    transform:scaleX(0);
    z-index:-1;
  }
  
  ul li a:hover:before{
    transition:transform 0.5s;
    transform-origin:left;
    transform:scaleX(1);
  }
.navbar{
    display: flex;
    background: $color-main;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2rem;
    width: 100%;
    

}

.navbar-links{
    flex:1;
    display: flex;
    justify-content: space-between;
    align-items:baseline;
    transition: all 0.3s linear;
    color: whitesmoke;
    &:hover{
        color:$color-orn;
    }
   
}
.navbar-links_container{
    padding-right: 5rem;
    top: 2rem;

}
.navbar-links_container :link{
    &:visited{
        
        color: #b5b5b5;
    }
}


.navbar-links_logo{
    margin-right: 1rem;
    
}

.navbar-links_logo img{
    width: 250px;
    margin-top: 0.5rem;
    margin-left: 2rem;
    
}
.navbar-links_container ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding-top: 0px;
    padding-right: 0px;
    
    
}

.navbar-links_container ul li,
.navbar_menu_container-links ul li {
    justify-content: center;
    font-family: $font-family;
    color: #fff;
    font-weight: 300;
    font-size: 1rem;
    line-height: 25px;
    text-transform:capitalize;
    margin: 0 2rem;
    cursor: pointer;
    transition: $mainTransition;
    
}
.navbar-links_container ul li
:hover{
   color:$color-orn;
}
.navbar_menu_container-links ul li
 :hover{
   color:$color-orn;
}


.navbar-menu{
    margin-right: 1rem;
    display: none;
    position: relative;
    color: $color-main;
    
    
}
.navbar_menu img{
    cursor: pointer;
   
}
.navbar-menu_container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column; 
    text-align: end;
    background: $color-main;
    padding: 1rem;
    position: absolute;
    top: 25%;
    right: 10%;
    margin-top: 2rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
    transition: $mainTransition;
    &:hover{
        color:$color-orn;
    }
   


}
.navbar_menu_container-links ul{
    flex-direction: column;
}
.navbar_menu_container-links ul li{
    margin: 1rem 0;
    list-style: none;
    padding-top: 15px;
    padding-right: 80px;

    
}
@media  screen and (min-width: 1050px) {
    .navbar_menu{
        display: none;
    }
    
}

@media  screen and (max-width: 1280px) 
{
    
    .navbar-links_container{
        display: none;
        
        
    }
    .navbar_menu{
        display: flex;
        transition: $mainTransition;
        
    &:hover{
        color:$color-orn;
    }

    }
    
}
@media screen and (max-width: 700px) {
    .navbar{
        // padding: 2rem 4rem;
    }
    .navbar-links_logo img{
        width: 150px;
        margin-left: 0.5rem;
        
    }
    .navbar_menu{
        display: flex;
        transition: $mainTransition;
        // right: 20px;
        top: 50px;
    &:hover{
        color:$color-orn;
    }

    }
}

@media screen and (max-width:550px){
    .navbar{
        padding: 1,5rem;
        padding-top: 2rem 2rem;
    }
    .navbar_menu{
        display: flex;
        transition: $mainTransition;
        right: 1rem;
        top: 50px;
    &:hover{
        color:$color-orn;
    }

    }

    .navbar_menu_container{
        
        right: 10px;
        
    }
    
}

